import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, fromEvent, interval, timer } from 'rxjs';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpUtilityService } from './http-utility.service';
import { AuthService } from './auth-service.service';
import { Platform } from '@ionic/angular';
import { NgxMatDateFormats } from '@angular-material-components/datetime-picker';
import { MatDateFormats } from '@angular/material/core';
import { bufferTime, debounceTime, throttle, throttleTime } from 'rxjs/operators';
export interface FullScreenSettings {
    modeFullScreen: boolean,
    showFullScreenToggle: boolean,
    toggleX: string,
    toggleY: string
}

@Injectable({
    providedIn: 'root'
})
export class AppService {
    //full screen 
    public fullScreen = new BehaviorSubject<FullScreenSettings>({
        modeFullScreen: false,
        showFullScreenToggle: false,
        toggleX: "0px",
        toggleY: "0px"
    });
    public showFullScreenToggle = false;
    //current page
    private currentPageid;
    //domEventSubscription
    public domEvent: Subject<DomEvent> = new Subject<DomEvent>;
    //public domEventObservable = this.domEvent.asObservable();

    constructor(public storage: Storage, private http: HttpClient, public router: Router, private httpUtilitySrv: HttpUtilityService, private authSrv: AuthService, private platform: Platform) {
    }

    /* FULL SCREEN */
    //set fullscreen
    public setFullScreen(fullScreenSettings: FullScreenSettings) {
        this.fullScreen.next(fullScreenSettings);
    }
    //save fullscreen settings
    public saveFullscreenSettings(fullScreenSettings: FullScreenSettings) {
        this.storage.set("fullScreenSettings", {
            fullScreenSettings: fullScreenSettings,
        })
    }
    //get fullscreen object
    public getFullscreenSettings() {
        return new Promise<FullScreenSettings>((resolve, reject) => {
            this.storage.create().then(() => {
                this.storage.get('fullScreenSettings').then((res) => {
                    if (res && res.fullScreenSettings && (parseInt(res.fullScreenSettings.toggleX, 10) + 62) > window.innerWidth) {
                        res.fullScreenSettings.toggleX = "0px"
                        res.fullScreenSettings.toggleY = "0px"
                    }
                    if (res && res.fullScreenSettings && (parseInt(res.fullScreenSettings.toggleY, 10) + 32) > window.innerHeight) {
                        res.fullScreenSettings.toggleX = "0px"
                        res.fullScreenSettings.toggleY = "0px"
                    }
                    resolve(res);
                }).catch(() => {
                    reject(null);
                })
            }).catch(() => {
                reject(null);
            })
        });
    }
    //enter fullscreen
    public enterFullscreen() {
        this.getFullscreenSettings().then((fullScreen: FullScreenSettings) => {
            if (fullScreen) {
                fullScreen.modeFullScreen = true;
                this.setFullScreen(fullScreen);
            } else {
                let newFullScreenSettings: FullScreenSettings = {
                    modeFullScreen: true,
                    showFullScreenToggle: true,
                    toggleX: "0px",
                    toggleY: "0px"
                }
                this.setFullScreen(newFullScreenSettings);
            }
        })
    }
    //exit fullscreen
    public exitFullScreen() {
        this.getFullscreenSettings().then((fullScreen: FullScreenSettings) => {
            if (fullScreen) {
                fullScreen.modeFullScreen = false;
                this.setFullScreen(fullScreen);
            } else {
                let newFullScreenSettings: FullScreenSettings = {
                    modeFullScreen: false,
                    showFullScreenToggle: false,
                    toggleX: "0px",
                    toggleY: "0px"
                }
                this.setFullScreen(newFullScreenSettings);
            }
        })
    }
    /* --- */

    /* SETUP BY HOST */
    public async getSubBaseRules(): Promise<CssConfig> {
        return new Promise(async (resolve) => {
            if (!this.platform.is("android") && !this.platform.is("ios") && !this.platform.is('capacitor')) {
                //(document.baseURI.indexOf('localhost') != -1 ? 'https://ss-silvanus/services/' : document.baseURI) + 
                let env: string = this.httpUtilitySrv.getEnvironment();
                let configPath = env.toUpperCase().indexOf('PROD') != -1 ? "https://safomf-mfeassets-prod.azurewebsites.net/mfe-assets/styles/" :
                env.toUpperCase().indexOf('STAGE') != -1 ? 'https://safomf-mfeassets-stage.azurewebsites.net/mfe-assets/styles/': "https://safomf-mfeassets.azurewebsites.net/mfe-assets/styles/";
                let cssConfigs: CssConfig[] = await this._getCssConfig(configPath);
                cssConfigs = cssConfigs ? cssConfigs : [];
                let tmpConfig = cssConfigs.filter((config) => { return document.baseURI.indexOf(config.locationOrigin) != -1 })[0];
                if (tmpConfig)
                    resolve(tmpConfig)
                else
                    resolve({
                        locationOrigin: null,
                        folderPath: null,
                        appName: "GEM Suite",
                        host: "SERVICES"
                    })
            }
            else {
                resolve({
                    locationOrigin: null,
                    folderPath: null,
                    appName: "GEM Suite",
                    host: "SERVICES"
                })
            }
        });

    }
    public async loadSubBaseCss() {
        let subBaseRules = await this.getSubBaseRules();
        if (subBaseRules.folderPath) {
            //deleteme
            //subBaseRules.cssPath = subBaseRules.cssPath.replace("localhost:8100", "ee");
            //colors
            this.http.get(subBaseRules.folderPath + "/colors.json?" + new Date().getTime()).subscribe((res: any) => {
                if (res && res.colors) {
                    res.colors.forEach(color => {
                        document.documentElement.style.setProperty(`--${color.name}`, color.value);
                    });
                }
            })
            //style
            this.http.get(subBaseRules.folderPath + "/global.css?" + new Date().getTime(), { responseType: 'text' })
                .subscribe((css) => {
                    var style = document.createElement("STYLE");
                    style.innerText = css;
                    document.body.appendChild(style);
                });
        }
    }

    public getIsInFrame() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return false;
        }
    }
    /*public setStyleByIsInFrame() {
        /*if (this.getIsInFrame())
            if (this.getCurrentHost() == HOSTS.SERVICES) {
                this.http.get('assets/themes/iframe-theme.css', { responseType: 'text' })
                    .subscribe((css) => {
                        var style = document.createElement("STYLE");
                        style.innerText = css;
                        document.body.appendChild(style);
                    });
            }
    }*/
    private _getCssConfig(baseUrl: string): Promise<CssConfig[]> {
        return new Promise((resolve) => {
            this.http.get(baseUrl + 'cssConfig.json').subscribe({
                next: (res: CssConfig[]) => { resolve(res) },
                error: (res: CssConfig[]) => { resolve(null) }
            })
        })
    }
    /*public isInIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }

         //iframe
        /*alert("Url: " + window.location + "\n" +
            "Parent: " + window.parent.location + "\n" +
            "referrer: " + document.referrer + "\n" +
            "Referrer: " + document.referrer + "\n" +
            "IsIframe: " + this.isInIframe());
    }*/
    /* --- */

    /* CURRENT MENU PAGE */
    public setCurrentPageId(pageId) {
        this.currentPageid = pageId;
    }
    public getCurrentPageId() {
        return this.currentPageid;
    }

    //--- NAVIGATION ---//
    public navigate(route: string, replaceUrl?: boolean, isLogout?: boolean) {
        this.authSrv.getLastRoleAndAuthObject().then((res: any) => {
            if (res && res.authObject && res.authObject.role && res.lastRoleObject && res.authObject.role.id != res.lastRoleObject.id) {
                this.router.navigateByUrl(route, { replaceUrl: replaceUrl ? true : false });
            } else {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                let url: string = window.location.href.replace(this.httpUtilitySrv.getBaseUri(), "");
                let page =
                    isLogout ? null :
                        urlParams.get('page') ? urlParams.get('page') :
                            url.indexOf("login") == -1 && url.indexOf("page-not-found") == -1 && url.indexOf("decurtazione-punti") == -1 && url.indexOf("reset-password") == -1 ? url :
                                null;
                let params = page ? "?page=" + encodeURIComponent(page) : "";
                //(page ? "#page=" + page : '') + (action ? "#action=" + action : '') + (target ? "#target=" + target : '');
                /*
                if (params != '') {
                    params = params.replace("#", "?");
                    params = params.replace(/#/g, "&");
                }*/
                this.router.navigateByUrl(`${route == '/login' ? route : page ? '/' + page : route}${params}`, { replaceUrl: replaceUrl ? true : false });
            }
        })
    }
    public getQueryParams() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let params: any = {};
        urlParams.forEach((value, key) => {
            params[key] = value;
        })
        return params;
    }
    //--- END NAVIGATION ---//

    //-- DEVICE --//
    public isMobile() {
        return window.innerWidth <= 576 ? true : false;
    }

    //--- DOM EVENTS ---//
    public initEvents() {
        fromEvent(document, 'mousedown')
            .subscribe((event: Event) => {
                this.domEvent.next({ eventType: 'mousedown', event: event });
            })
        fromEvent(document, 'mousemove')
            .subscribe((event: Event) => {
                this.domEvent.next({ eventType: 'mousemove', event: event });
            })
        fromEvent(document, 'mouseup')
            .subscribe((event: Event) => {
                this.domEvent.next({ eventType: 'mouseup', event: event });
            })
    }
    //--- END DOM EVENTS ---//
}
export function getDateFormat() {
    let dateFormat: MatDateFormats = {
        parse: {
            dateInput: "DD/MM/YYYY"
        },
        display: {
            dateInput: "DD/MM/YYYY",
            monthYearLabel: "MMM YYYY",
            dateA11yLabel: "LL",
            monthYearA11yLabel: "MMMM YYYY"
        }
    };
    if (['en-US'].includes(navigator.languages[0]))
        dateFormat = {
            parse: {
                dateInput: "MM/DD/YYYY"
            },
            display: {
                dateInput: "MM/DD/YYYY",
                monthYearLabel: "MMM YYYY",
                dateA11yLabel: "LL",
                monthYearA11yLabel: "MMMM YYYY"
            }
        }
    return dateFormat;
}
export function getDateTimeFormat() {
    let dateFormat: NgxMatDateFormats = {
        parse: {
            dateInput: "DD/MM/YYYY HH:mm"
        },
        display: {
            dateInput: "DD/MM/YYYY HH:mm",
            monthYearLabel: "MMM YYYY",
            dateA11yLabel: "LL",
            monthYearA11yLabel: "MMMM YYYY"
        }
    };
    if (['en-US'].includes(navigator.languages[0]))
        dateFormat = {
            parse: {
                dateInput: "MM/DD/YYYY HH:mm"
            },
            display: {
                dateInput: "MM/DD/YYYY HH:mm",
                monthYearLabel: "MMM YYYY",
                dateA11yLabel: "LL",
                monthYearA11yLabel: "MMMM YYYY"
            }
        }
    return dateFormat;
}
export interface DomEvent {
    event: any,
    eventType: 'mousedown' | 'mousemove' | 'mouseup'
}

export interface CssConfig {
    locationOrigin: string,
    folderPath: string,
    appName: string,
    host: string
}
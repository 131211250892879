import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Pipe({
  name: 'translateMenu'
})
export class TranslateMenuPipe implements PipeTransform {

  constructor(public translate: TranslateService) { }

  transform(nome: string, argNoTranslationName: string, id:string): Promise<string> {
    if(id.startsWith('gem_toll.')){
      return this.translate.get('menu.gemtoll').toPromise().then((menu) => {
        if (menu[nome]) {
          return menu[nome];
        }
        else
          return argNoTranslationName;
      })
    }else if(id.startsWith('webpayments_int.')){
      return this.translate.get('menu.webpayments').toPromise().then((menu) => {
        if (menu[nome]) {
          return menu[nome];
        }
        else
          return argNoTranslationName;
      })
    }
    else{
      return this.translate.get('menu').toPromise().then((menu) => {
        if (menu[nome]) {
          return menu[nome];
        }
        else
          return argNoTranslationName;
      })
    }
  }
}
